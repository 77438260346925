//import store from "../../store";
const winterdienstRoutes = [
  {
    path: "/Winterdienst",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Winterdienst.vue"
      ), 
      
  },
];

export default winterdienstRoutes;
