<template>
  <div class="sitebackground">
    <router-view></router-view>
    <widget-container-modal />
  </div>
</template>

<script>
//import VueCookies from "vue-cookies";
import { container } from "jenesius-vue-modal";
//import { openModal } from "jenesius-vue-modal";
//import { mapState } from "vuex"

export default {
  name: "App",
  computed: {
    token() {
      return this.$store.getters.token;
    },
  },
  components: {
    WidgetContainerModal: container,
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~animate.css/animate.min.css";
@import "./eigen_css/kito.css";

* {
  box-sizing: border-box;   
  -webkit-text-size-adjust: none;  
}
input,textarea{
   font-size: 12px !important;
}
.container-fluid ,.row{
  margin:0;
}


.bg-vue {
  background-color: #74717200;
  color: white;
}
.bg-vue2 {
  background-color: rgb(114, 47, 47);
  color: white;
}
.text-vue {
  color: rgb(52, 73, 94);
}
.text-vue2 {
  color: rgb(65, 184, 131);
}

.pm-01 {
  padding: 0;
  margin: 0;
}
.sitebackground {
  background-color: rgb(111, 111, 110);
  color: white;
  padding: 0px;
}
.footer {
  background-color: rgb(111, 111, 110);
  color: white;
  padding: 0px;
}
.unten {
  background-image: url(assets/Logo2.png);
  background-color: rgb(111, 111, 110);
  background-size: 550px;
  background-repeat: no-repeat;
  background-position: right;
  min-height: 390px;
  padding: 0px;
  padding-left: 100px;
}
.scroll {
  overflow: auto;
  height: 700px;
}
.logoObenLinks {
  position: fixed;
  top: 10;
  left: 10;
  z-index: 100;

  height: 120px;
  object-fit: fill;
  object-position: left top;
}
.hemihead {
  font-family: "Hemihead", Helvetica, Arial;
  font-size: 22px;
}
@font-face {
  font-family: "Hemihead";
  src: local("Hemihead"), url(./fonts/hemihead.ttf) format("truetype");
}
textarea {
  max-width: 100%;
}



.rsb{
  padding-top: 100px;
  }

@media only screen and (min-width: 769px) {
  .nav {
  background-color: rgb(134, 134, 134);
  color: white;
  padding: 0;
  align-items: center;
  }
  .nav-deactiv {
    background-color: rgb(134, 134, 134);
    color: white;
    padding: 0;
    align-items: center;
    width: 240px;
    height: 30px;
    text-align: center;
  }
  .nav-active {
    background-image: url("assets/navBackground.png");
    background-size: 100% 100%;
    padding: 0;
    color: white;
    width: 240px;
    height: 30px;
    text-align: center;
  }
  .nav-link {
    color:white !important;
    display:block;
  }
  .handyOnly{
    display: none;
  }
  
.ddown-menu {
  background-color: #74717270;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  width: 240px;
  top: -190px;
}

.ddownLi {
  list-style-type: none;
}
.ddUnterstrich {
  border-bottom: 1px solid rgb(219, 219, 219);
}
.ddown-link {
  text-decoration: none;
  font-size: 18px;
  background-color: #74717200;
  margin-left: 8px;
}
.ddownLi a:hover {
  list-style-type: none;
  color: black;
}
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
 
  textarea {
  max-width: 100%;
  }
  label{
    display:block;
    }
   
   .datenschutz{
     margin-top:50px;
     padding-left: 90px;
   }
   
  .nav {
    background-color: rgb(134, 134, 134);
    color: white;
  }
  .nav a{    
   /*  margin-bottom: 10px !important;*/
  }
  .nav-link {
    color:white !important;
    display:flex;
  }
  .nav-deactiv {
    background-color: rgb(134, 134, 134);
    color: white;
    align-items: center;
    width: 240px;
    height: 40px;
  }
  .nav-active {
    background-image: url("assets/navBackground.png");
    background-size: 100% 100%;
    color: white;
    width: 240px;
    height: 40px;
    padding-left: 20px !important;
  }
  
  
.ddown-menu {
  background-color: #74717270;
  padding-left: 20px;
  padding-right: 10px;
  position: absolute;
  width: 240px;
  top: -172px;
}

.ddownLi {
  list-style-type: none;
}

 
.ddUnterstrich {
  border-bottom: 1px solid rgb(219, 219, 219);
}
.ddown-link {
  text-decoration: none;
  font-size: 18px;
  background-color: #74717200;
  margin-left: 25px;
}
  .unten {
  padding:0;
  }

  .contact_form label {
      width: 100%;
      margin-top: 3px;
      display: inline-block;
      padding: 3px;
  }
  .logoObenLinks {
    position: fixed;
    top: 10;
    left: 10;
    z-index: 100;

    height: 50px;
    object-fit: fill;
    object-position: left top;
  }
  .footer {
    background-color: rgb(111, 111, 110);
    color: white;
  /**margin-left: 30%;**/
  }
.keinHandy{
  display: none;
}
}
</style>
