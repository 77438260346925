//import store from "../../store";
const graupflegeRoutes = [
  {
    path: "/Graupflege",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Graupflege.vue"
      ), 
      
  },
];

export default graupflegeRoutes;
