import startRoutes from "./start-router";
import auftragRoutes from "./auftrag-router";
import kontaktRoutes from "./kontakt-router";

import winterdienstRoutes from "./winterdienst-router";
import graupflegeRoutes from "./graupflege-router";
import gruenanlagenRoutes from "./gruenanlagen-router";
import hausmeisterRoutes from "./hausmeister-router";
import sonderauftragRoutes from "./sonderauftrag-router";
import entsorgungRoutes from "./entsorgung-router";

const routes = [
                ...startRoutes,
                ...auftragRoutes,
                ...kontaktRoutes,
                ...winterdienstRoutes,
                ...graupflegeRoutes,
                ...gruenanlagenRoutes,
                ...hausmeisterRoutes,
                ...sonderauftragRoutes,
                ...entsorgungRoutes,
            ];

export default routes;
