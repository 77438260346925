//import store from "../../store";
const gruenanlagenRoutes = [
  {
    path: "/Gruenanlagen",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Gruenanlagen.vue"
      ), 
      
  },
];

export default  gruenanlagenRoutes ;
