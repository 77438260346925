//import store from "../../store";
const sonderauftragRoutes = [
  {
    path: "/Sonderauftrag",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Sonderauftrag.vue"
      ), 
      
  },
];

export default sonderauftragRoutes;
